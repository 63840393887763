import _arrayWithHoles from "./arrayWithHoles.js";
import _iterableToArrayLimit from "./iterableToArrayLimit.js";
import _unsupportedIterableToArray from "./unsupportedIterableToArray.js";
import _nonIterableRest from "./nonIterableRest.js";
var exports = {};
var arrayWithHoles = _arrayWithHoles;
var iterableToArrayLimit = _iterableToArrayLimit;
var unsupportedIterableToArray = _unsupportedIterableToArray;
var nonIterableRest = _nonIterableRest;

function _slicedToArray(arr, i) {
  return arrayWithHoles(arr) || iterableToArrayLimit(arr, i) || unsupportedIterableToArray(arr, i) || nonIterableRest();
}

exports = _slicedToArray, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;